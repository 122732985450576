@import './src/mixins.scss';
@import './src/variables.scss';
@import './src/functions.scss';

nav {
  z-index: 950;
  position:fixed;
  width:100vw;
  height:100vh;
  padding: $padding-top 30px 30px 30px;
  background-color:$white;
  left:100%;
  opacity:0;
  transition: all 0.25s;
  display: flex;
  flex-direction: column;
  gap:20px;

  &.active {
    left:0;
    opacity:1;
  }

  a {
    font-size:2rem;
    font-weight: 300;
    color: #000000;
    text-transform: uppercase;
    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (min-width:$breakpoint-tablet) {
  nav {
    a { font-size:2rem; }
    &.active {
      left:calc(100% - 45%);
    }
  }
}

@media screen and (min-width:$breakpoint-xl) {
  nav {
    a { font-size:2.2rem; }
    &.active {
      left:calc(100% - 35%);
    }
  }
}
