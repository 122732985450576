@import './src/functions';
@import './src/variables';
@import './src/mixins';

.container {
  padding:$padding-container;
  @include flexbox(20px, column);
  p {
    font-size:pxToRem(18);
    line-height: 1.3;
    max-width: 100%;
  }
}

main.bg {
  background-image: url("../../../public/header-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  min-width: 100vw;
}

.slider {
  .slide {
    .content {
      flex-direction: column;
      .foto {
        // display:none;
        img {
          width:100%;
          height:auto;
          filter:grayscale(1);
        }
      }
      .info {
        @include flexbox(0, column);
        justify-content: center;
        padding:30px;
        background-image: url("../../../public/header-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
      }
    }
    p {
      color: $white;
      line-height: 1.5;
      font-size: pxToRem(16);
      font-weight: 300;
      strong {
        color:$gray;
      }
    }
  }

  .slick-dots li button:before {
    width: 30px !important;
    height: 30px !important;
  }
}

@media screen and (min-width: $breakpoint-tablet) {
  main {
    padding-top:200px;
  }

  .slider {
    .slide {
      padding: 20px 0;
      p {
        font-size: pxToRem(18);
        font-weight: 300;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-desktop) {
  .slider {
    .slide {
      padding: 20px 0;
      .content {
        @include flexbox(20px, row);
        .info {
          @include flexbox(0, column);
          justify-content: center;
          padding:30px;
          border:1px solid $dark-gray;
        }
        .foto {
          display: initial;
          img {
            width:auto;
            height:300px;
            border:10px solid $blackened-blue;
          }
        }
      }
      p {
        font-size: pxToRem(24);
        font-weight: 300;
      }
    }
  }
}
