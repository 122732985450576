@import './functions.scss';
@import './variables.scss';
@import './mixins.scss';

html, body {
  padding:0;
  margin:0;
}

* {
  box-sizing: border-box;
  text-decoration: none;
}

main, nav {
  padding: 120px 30px 30px;
}

a {
  color:rgba(0,0,0,1);
}

main {
  h1 {
    font-size: 1.75rem;
    font-weight: 300;
    text-transform: uppercase;
  }
  .container {
    padding:$padding-container;
  }
}

@media screen and (min-width:$breakpoint-tablet) {
  main, nav {
    padding: 150px 50px 40px;
  }

  main {
    h1 {
      font-size: 2.5rem;
    }
  }
}
