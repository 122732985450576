@import './src/functions.scss';
@import './src/variables.scss';
@import './src/mixins.scss';

header {
  position: fixed;
  z-index: 900;
  right:0;
  left:0;
  padding:30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom:1px solid $dark-gray;

  .logo {
    height:30px;
  }
}

@media screen and (min-width:$breakpoint-tablet) {
  header {
    padding:50px;
  }
}
