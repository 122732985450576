@import './src/functions';
@import './src/variables';
@import './src/mixins';

.container {
  padding:$padding-container;
  @include flexbox(50px, column);

  > .pic {
    display:flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin:0 auto;
    height:250px;
    background-image:url('../../../public/pages/contacto.jpg');
    background-size:cover;
    background-position: center;
  }

  .content {
    @include flexbox(20px, column);
    justify-content: center;

    .map {
      a {
        display: block;
        width:100%;
        height:300px;
        border:1px solid #ccc;
        background-image:url('../../../public/map.png');
        background-repeat: no-repeat;
        background-size: 230%;
        background-position: center;
      }
    }

    .info {
      @include flexbox(50px, column);
      font-size: pxToRem(14);
      .address {
        @include flexbox(5px, column);
      }

      .partner {
        @include flexbox(5px, column);
        .name {
          text-transform: uppercase;
          font-weight:700;
        }
      }
    }
  }
}

@media screen and (min-width:$breakpoint-mobile) {
  .container {
    .content {
      .map {
        a {
          height: 350px;
          background-size: 200%;
        }
      }
    }
  }
}

@media screen and (min-width:$breakpoint-desktop) {
  .container {
    .content {
      flex-direction: row;

      .map {
        a {
          width: 400px;
          height: 400px;
          background-size: 290%;
        }
      }
    }
  }
}
