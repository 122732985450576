@import './src/functions';
@import './src/variables';
@import './src/mixins';

.container {
  @include flexbox(20px, column);
  .breadcrumbs {
    @include flexbox(5px, row);
    align-items: center;
    font-size:pxToRem(18);
    text-decoration: none;
    span {
      text-decoration: underline;
    }
    &:hover {
      * {
        text-decoration: none;
      }
    }
  }

  .content {
    @include flexbox(0, row);
    justify-content: center;
    .infoPic {
      @include flexbox(10px, column);
      width:500px;
      max-width: 100%;
      .pic {
        order:0;
        width:500px;
        max-width:100%;
        height:363px;
        background-size: cover;
        background-position: center;
      }
      .info {
        order:1;
        @include flexbox(40px, column);
        text-align: right;
        text-transform: uppercase;
        padding:0 0 50px 0;
        .name {
          font-size:pxToRem(30);
          text-align: left;
        }
        .lines {
          @include flexbox(20px, column);
          width:500px;
          max-width: 100%;
          color:$dark-gray;
          font-size:pxToRem(12);
          font-weight: 700;
        }
      }
      .gallery {
        order:2;
        width:500px;
        max-width: 100%;
        @include flexbox(10px, column);
        .img {
          width:100%;
          height:150px;
          background-position: center;
          background-size: cover;
        }
      }
    }
  }
}

@media screen and (min-width:$breakpoint-desktop) {
  .container {
    .content {
      .infoPic {
        @include flexbox(20px, row);
        width: 100%;
        max-width: 900px;
        flex-wrap: wrap;
        justify-content: flex-end;
        .pic {
          order:1;
          width:500px;
          height:500px;
        }
        .info {
          order:0;
          gap:100px;
          align-items: flex-start;
          padding:0;
          width:380px;
          max-width: 100%;
          * {
            text-align: left;
          }
          .name {
            font-size:pxToRem(45);
            font-weight: 500;
          }
        }
        .gallery {
          order:2;
          width:500px;
          max-width: 100%;
          @include flexbox(10px,row);
          justify-content: space-between;
          .img {
            height:100px;
          }
        }
      }
    }
  }
}
