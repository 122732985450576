@import './src/functions';
@import './src/variables';
@import './src/mixins';

.container {
  padding:$padding-container;

  .listItems {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: stretch;
    column-gap:5px;
    row-gap:5px;

    .project {
      @include flexbox(10px, column);
      width:110px;
      padding: 10px;
      transition: all 0.2s;
      text-decoration: none;

      .img {
        height:90px;
        background-position: center;
        background-size: cover;
        filter: grayscale(1);
        max-width: 100%;
      }

      .projectName {
        font-size: pxToRem(12);
        text-transform: uppercase;
        text-align: right;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
      }

      &:hover {
        background-color: $blackened-blue;

        .img {
          filter: initial;
        }

        .projectName {
          color: $white;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-tablet) {
  .container {
    .listItems {
      column-gap: 5px;
      row-gap: 20px;
      .project {
        width:120px;
        .img {
          width:100px;
          height:100px;
          max-width: initial;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-desktop) {
  .container {
    .listItems {
      column-gap: 10px;
      row-gap: 50px;
      .project {
        padding:10px;
        width:140px;
        .img {
          width:120px;
          height:120px;
          max-width: initial;
        }
      }
    }
  }
}
