@import './src/functions';
@import './src/variables';
@import './src/mixins';

.container {
  padding:$padding-container;
  @include flexbox(50px, column);

  > .pic {
    display:flex;
    align-items: center;
    justify-content: center;
    width:100%;
    margin:0 auto;
    height:250px;
    background-image:url('../../../public/pages/equipo.jpg');
    background-size:cover;
    background-position: center;
  }

  .team {
    @include flexbox(50px, column);
    margin:0 auto;
    width:100%;
    .partners {
      @include flexbox(30px, row);
      flex-wrap: wrap;
      font-size:pxToRem(16);
      .partner {
        @include flexbox(10px, column);
        width:calc((100% - 30px) / 2);
        .pic {}
      }
    }
    .divider {
      width:100%;
      background: $blackened-blue;
      height:1px;
    }
    .staff {
      @include flexbox(30px, row);
      flex-wrap: wrap;
      font-size:pxToRem(14);
      .person {
        @include flexbox(10px, column);
        width:calc((100% - 30px) / 2);
      }
    }
    .name {
      font-weight: 700;
      text-transform: uppercase;
    }
    .info {
      @include flexbox(6px, column);
    }
  }
}

@media screen and (min-width:$breakpoint-tablet) {
  .container {
    .team {
      width:700px;
      .partners {
        gap:70px;
        .partner {
          width:calc((100% - 70px) / 2);
        }
      }
      .staff {
        gap:70px;
        .person {
          width:calc((100% - 70px) / 2);
        }
      }
    }
  }
}
